import React from "react";
import RedStrikeThrough from "../components/Home/RedStrikeThrough";

import Developer from "../svgs/Developer.svg";
import Productive from "../svgs/Productive.svg";
import Interview from "../svgs/Interview.svg";
import Season from "../svgs/Season.svg";
import Lessons from "../svgs/Lessons.svg";
import Features from "../svgs/Features.svg";
import Time from "../svgs/Time.svg";
import michael from "../images/Michael.jpeg";
import paul from "../images/Paul.jpeg";
import kate from "../images/Kate.jpeg";

export const infoGraphics = [
    {
        title: "An essential skill for the Cloud",
        text: `
            In the time of Cloud, Docker, and 
            Kubernetes, you are required to 
            use the Linux command line daily 
        `,
        graphic: <Developer height="160" width="253" />,
    },
    {
        title: "Grow your flexibility",
        text: `
            Tap into hidden functions of your system
            with shortcuts, time-saving commands, and tools
        `,
        graphic: <Productive height="160" width="186" />,
    },
    {
        title: "Land high-paying opportunities",
        text: `
           Gain skills that are a sign of a good developer
           in any employer’s mind
        `,
        graphic: <Interview height="160" width="211" />,
    },
    {
        title: "Save time, work on more projects",
        text: `
            Optimize your process and workflow.
            Get more done with less hardware resources
        `,
        graphic: <Season height="160" width="264" />,
    },
];

export const stories = [
    {
        title: (
            <span>
                Hands-On Learning That Sticks
            </span>
        ),
        bullets: [
            <span>
                <b>Skip the theory</b> and dive straight into hands-on exercises
            </span>,
            <span>
                <b>Practice real terminal commands</b> in an actual terminal window directly in your browser
            </span>,
            <span>
                <b>Platform tracks your input</b> and offers immediate guidance
            </span>,
            <span>
                <b>Avoid mistakes</b> and quickly learn the correct way to use commands
            </span>,
        ],
        graphic: <Features />,
    },
    {
        title: "The Ultimate Terminal Learning Package",
        bullets: [
            <span>
                <b>Main Course:</b> Get the essentials fast with interactive lessons
            </span>,
            <span>
                <b>Command Walkthroughs:</b> Learn popular commands step by step
            </span>,
            <span>
                <b>Downloadable Cheatsheets:</b>  Save useful reference sheets for later
            </span>,
            <span>
                <b>Extra Articles:</b> Dive deeper with advanced and interesting topics
            </span>,
        ],
        graphic: <Lessons />,
    },
    {
        title: "Learn Fast, Work Smarter",
        bullets: [
            <span>
                I get it — <b>time is valuable</b>. That’s why this course is designed to teach you terminal skills fast, so you can start using them right away in your projects
            </span>,
            <span>
                The main course zeroes in on the <b>most important terminal skills</b>, so you learn what you need fast without wasting time on unnecessary details
            </span>,
            <span>
                Finish the course and grab a <b>certificate</b> that shows off your terminal skills.  
            </span>
           ],
        graphic: <Time />,
    },
];

export const steps = [

        {
            title: "Why do I need it?",
            paragraphs: [
                `
                    Developers use the terminal for everything from navigating files to running scripts, 
                    managing servers, and version control with Git. Suddenly, tasks 
                    that used to take forever are done in seconds, and everything just clicks.
                `,
                `
                    At first, the terminal can feel pretty daunting — just a blank 
                    screen, and it seems like one wrong command could mess everything up. 
                    But once you get the basics, it’s a whole different ballgame. 
                `,
                `
                    What once felt overwhelming becomes one of the most efficient tools in your toolkit, 
                    and you actually start enjoying it. Once you’re comfortable, you’ll wonder how you ever coded without it.
                `,
            ],
        },
        {
            title: "How does it work?",
            paragraphs: [
                `
                    I built this platform from scratch over the past two years, 
                    and it's 100% custom-made for one purpose only — learning the 
                    terminal. Unlike other platforms that try to cover everything, 
                    this one is all about mastering the command line. 
                `,
                `
                    You’ll 
                    learn by doing real, interactive exercises right in a terminal 
                    window in your browser. The platform tracks everything you do 
                    and gives you guidance along the way. 
                `,
                `
                    The main course focuses 
                    on teaching you the most important terminal skills fast, but 
                    there's also a bunch of extras — tutorials for popular commands, 
                    articles about terminal history and how it all works under the 
                    hood. Plus, you get downloadable cheatsheets you can save on 
                    your computer for easy reference whenever you need them.
                `,
            ],
        },
        {
            title: "Who is the author?",
            paragraphs: [
                `
                    I’m Alen Čaljkušić, and I’ve been in software development and platform 
                    engineering for over 10 years. 
                `,
                `
                    My career includes working with big 
                    names like Activision Blizzard, various startups, telecommunication 
                    companies, and large banks. I’m also an AWS Certified Solutions Architect 
                    - Professional and hold full Kubernetes certification. 
                `,
                `
                    My background covers everything from large-scale systems to the latest in cloud 
                    and container tech.
                
                `,
            ],
        },
];

export const testemonials = [
    {
        name: "Michael Hawks",
        role: "Software Developer",
        rating: 5,
        text: `
            This platform is a rare find. Most of the learning platforms
            are in boring text form, which can be difficult to comprehend
            at times. With learncmd.io, I was able to get an interactive
            terminal right away and start practicing the commands. They
            have tutorials that walk you through each command with
            step-by-step instructions. In my opinion, it's an absolute must
            for anyone who wants to learn Command Line!
        `,
        image: michael,
    },
    {
        name: "Paul Felton",
        role: "Oil industry engineer",
        rating: 5,
        text: `
            I am an engineer in the oil industry, and I needed to learn
            Linux Terminal for my job. When I found out about learncmd.io beta
            testing program, it seemed like an opportunity too good to
            pass up. The site has a lot of information about how the
            terminal works, but it really shines with interactive tutorials
            that help me quickly learn the commands I need to know.
        `,
        image: paul,
    },
    {
        name: "Kate Hooper",
        role: "Software Developer",
        rating: 5,
        text: `
            I'm a total beginner when it comes to command
            line and I was looking for a good place to start. learncmd.io
            is just what I needed! The tutorials are very well-made, and
            interactive which makes learning the basics of Linux so much
            easier, especially if you have minimal knowledge about operating
            systems. Highly recommended for all beginners!
        `,
        image: kate,
    },
];

export const plans = [
    {
        title: "Starter",
        bulletsDescription: "Try out LearnCMD for free:",
        bullets: [
            "2 Main Course modules",
            "2 Command walkthroughs",
            "Limited downloadable cheat-sheets",
        ],
        priceDisclaimer: "No credit card required",
    },
    {
        title: "Premium",
        bulletsDescription:
            "Full access to all available content:",
        bullets: [
            "Full Main Course",
            "10 Command walkthroughs",
            "Downloadable education materials",
            "Downloadable command cheat-sheets",
            "Certificate of completion",
        ],
        price: 89,
        priceDisclaimer: "7 days money back guarantee",
    },

];
