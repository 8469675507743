import React from "react";
import styled from "styled-components";
import Breakpoint from "../../constants/Breakpoint";
import Color from "../../constants/Color";
import Spacing from "../../constants/Spacing";
import ParagraphTitle from "./ParagraphTitle";

interface Props {
    index: number;
    title: string;
    paragraphs: string[];
}

export const Steps = styled.div`
    margin: ${Spacing.SPACING_7} 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 960px;

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        flex-direction: column;
        align-items: center;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    width: 290px;

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        margin-top: ${Spacing.SPACING_4};

        &:first-child {
            margin-top: 0px;
        }
    }
`;

const IndexContainer = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: ${Color.BRAND_1};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0px ${Spacing.SPACING_3} 0px;
`;

const Index = styled.p`
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    margin: 0;
    color: ${Color.TEXT_LIGHT_1};
`;

const ParagraphColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const Paragraph = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: ${Color.TEXT_DARK_1};
    margin: 0px;

    margin-bottom: ${Spacing.SPACING_2};

    &:last-child {
        margin-bottom: 0px;
    }
`;

const Step = ({ index, title, paragraphs }: Props) => {
    return (
        <Container>
            <ParagraphTitle>{title}</ParagraphTitle>
            <ParagraphColumn>
                {paragraphs.map((paragraph, paragraphIndex) => {
                    return (
                        <Paragraph key={paragraphIndex}>{paragraph}</Paragraph>
                    );
                })}
            </ParagraphColumn>
        </Container>
    );
};

export default Step;
