import styled from "styled-components";
import Breakpoint from "../../constants/Breakpoint";
import Color from "../../constants/Color";
import { SpacingType } from "../../constants/Spacing";

interface Props {
    marginTop?: SpacingType;
    marginBottom?: SpacingType;
}

const ParagraphTitle = styled.h1<Props>`
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    text-align: left;
    min-height: 50px;
    color: ${Color.TEXT_DARK_1};
    margin-top: ${({ marginTop }: Props) => marginTop || 0};
    margin-bottom: ${({ marginBottom }: Props) => marginBottom || 0};

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        font-size: 20px;
    }
`;

export default ParagraphTitle;
