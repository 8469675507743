import React, { useCallback, useState } from "react";

import Layout from "../components/Common/Layout";
import Title from "../components/Home/Title";
import Main from "../components/Common/Main";
import Section from "../components/Common/Section";
import Subtitle from "../components/Home/Subtitle";
import Spacing from "../constants/Spacing";
import Button, { ButtonType, ButtonSize } from "../components/Common/Button";
import InfoGraphic, { InfoGraphics } from "../components/Home/InfoGraphic";
import ButtonRow from "../components/Home/ButtonRow";
import Animation from "../components/Home/Animation";
import Color from "../constants/Color";
import Story, { StoryType } from "../components/Home/Story";
import SectionTitle from "../components/Home/SectionTitle";
import Step, { Steps } from "../components/Home/Step";
import Testemonials from "../components/Home/Testemonials";
import {
    infoGraphics,
    steps,
    stories,
    testemonials,
    plans,
} from "../data/homeData";
import Tabs, { Tab } from "../components/Common/Tabs";
import Modal from "../components/Common/Modal";
import Plan from "../components/Home/Plan";
import Breakpoint from "../constants/Breakpoint";
import SubscribeByMail from "../components/Common/SubscribeByMail";
import Red from "../components/Home/Red";

const IndexPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    return (
        <Layout title="Learn CMD">
            {isModalOpen && (
                <Modal closeAction={closeModal}>
                    <SubscribeByMail close={closeModal} />
                </Modal>
            )}
            <Main>
                <Section>
                    <Title
                        marginTop={Spacing.SPACING_7}
                        marginBottom={Spacing.SPACING_3}
                    >
                        Learn the Terminal in 
                        <br />
                        <Red><u>Record Time</u></Red>
                        <br />
                    </Title>
                    <Subtitle marginBottom={Spacing.SPACING_5}>
                        You won’t just watch tutorials — you’ll dive in and practice. Every lesson is<br />
                        interactive, so you’re <b>learn-by-doing</b>, not just reading.
                        <br />
                    </Subtitle>
                    <ButtonRow hideOn={Breakpoint.TABLET_AND_DOWN}>
                        <Button
                            buttonType={ButtonType.PRIMARY}
                            buttonSize={ButtonSize.LARGE}
                            onClick={() => window.location.href = 'https://app.learncmd.io'}
                        >
                            Get Started
                        </Button>
                        <Button
                            buttonType={ButtonType.SECONDARY}
                            buttonSize={ButtonSize.LARGE}
                            marginLeft={Spacing.SPACING_2}
                            onClick={openModal}
                        >
                            Watch Demo
                        </Button>
                    </ButtonRow>
                    <ButtonRow hideOn={Breakpoint.DESKTOP_AND_UP}>
                        <Button
                            buttonType={ButtonType.PRIMARY}
                            buttonSize={ButtonSize.LARGE}
                            onClick={openModal}
                        >
                            Get Started
                        </Button>
                        <Button
                            buttonType={ButtonType.SECONDARY}
                            buttonSize={ButtonSize.LARGE}
                            marginTop={Spacing.SPACING_2}
                            onClick={openModal}
                        >
                            Watch Demo
                        </Button>
                    </ButtonRow>
                </Section>
                <Section backgroundColor={Color.BACKGROUND_LIGHT_2}> 
                <Title
                        marginTop={Spacing.SPACING_7}
                    >
                        What do I get?
                    </Title>
                    </Section>
                {stories.map(
                    (
                        { title, graphic, bullets, bulletsDescription },
                        index
                    ) => {
                        const isEven = index % 2 === 0;
                        return (
                            <Section
                                key={index}
                                backgroundColor={
                                    isEven
                                        ? Color.BACKGROUND_LIGHT_2
                                        : Color.BACKGROUND_LIGHT_2
                                }
                            >
                                <Story
                                    title={title}
                                    graphic={graphic}
                                    bullets={bullets}
                                    bulletsDescription={bulletsDescription}
                                    storyType={
                                        isEven
                                            ? StoryType.GRAPHIC_FIRST
                                            : StoryType.TEXT_FIRST
                                    }
                                />
                            </Section>
                        );
                    }
                )}

                <Section backgroundColor={Color.BACKGROUND_LIGHT_1}>
                    <Steps>
                        {steps.map(({ title, paragraphs }, index) => {
                            return (
                                <Step
                                    title={title}   
                                    paragraphs={paragraphs}
                                    index={index + 1}
                                    key={index}
                                />
                            );
                        })}
                    </Steps>
                </Section>

                <Section backgroundColor={Color.BACKGROUND_LIGHT_2}>
                    <Title marginTop={Spacing.SPACING_7}>
                        Here is what our first users say
                    </Title>
                    <Testemonials testemonials={testemonials} />
                </Section>
                <Section>
                    <Title
                        marginTop={Spacing.SPACING_7}
                        marginBottom={Spacing.SPACING_7}
                    >
                        Start learning now
                    </Title>
                    <Tabs marginBottom={Spacing.SPACING_7}>
                        {plans.map(
                            (
                                {
                                    title,
                                    bulletsDescription,
                                    bullets,
                                    price,
                                    priceDisclaimer,
                                },
                                index
                            ) => {
                                return (
                                    <Tab label={title} key={index}>
                                        <Plan
                                            title={title}
                                            bulletsDescription={
                                                bulletsDescription
                                            }
                                            bullets={bullets}
                                            price={price}
                                            priceDisclaimer={priceDisclaimer}
                                            action={openModal}
                                        />
                                    </Tab>
                                );
                            }
                        )}
                    </Tabs>
                </Section>
            </Main>
        </Layout>
    );
};

export default IndexPage;
