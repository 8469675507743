import React, {
    ReactNode,
    Fragment,
    useState,
    useCallback,
    useEffect,
} from "react";
import { Link as UnstyledLink } from "gatsby";
import { Helmet } from "react-helmet";
import styled, { createGlobalStyle } from "styled-components";

// Temp cookie override
import Cookie from "js-cookie";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useLocation } from "@reach/router";

import "normalize.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/400.css";

import Button, { ButtonType, ButtonSize } from "./Button";
import Modal from "./Modal";
import SubscribeByMail from "./SubscribeByMail";
import Breakpoint from "../../constants/Breakpoint";
import Color from "../../constants/Color";
import Spacing from "../../constants/Spacing";
import FontSize from "../../constants/FontSize";
import Logo from "../../svgs/Logo.svg";
import Hamburger from "../../svgs/Hamburger.svg";
import CloseIcon from "../../svgs/CloseIcon.svg";
// Temp cookie override
// import GDPRModal from "./GDPRModal";

// Temp cookie override
const GDPR_COOKIE = "gdpr-analytics-enabled";

const GlobalStyle = createGlobalStyle`
    html {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        width: 100vw;
        margin: 0px;
        padding: 0px;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    body { font-family: "Roboto", sans-serif; }

    *,
    *::before,
    *::after {
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit;
    }
`;

interface ContainerProps {
    preventScroll: boolean;
}

const Container = styled.div<ContainerProps>`
    background-color: ${Color.BACKGROUND_LIGHT_1};
    color: ${Color.TEXT_DARK_1};
    ${({ preventScroll }: ContainerProps) => {
        return preventScroll ? "overflow-y: hidden;" : "overflow-y: scroll;";
    }}
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const ScrollContainer = styled.div`
    min-height: min-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;

const Header = styled.header`
    width: 100%;
    height: 64px;
    background-color: ${Color.BRAND_1};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px ${Spacing.SPACING_2};
`;

const HeaderLeftSocket = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
`;

const HeaderCentralSocket = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        display: none;
    }
`;

const HeaderRightSocket = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
`;

interface NavProps {
    inFooter?: boolean;
}

const Nav = styled.nav<NavProps>`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        ${({ inFooter }: NavProps) => {
            if (inFooter) {
                return `margin-top: ${Spacing.SPACING_2};`;
            }

            return ``;
        }}
    }

    .activeLink {
        font-weight: 700;
    }
`;

interface HamburgerProps {
    onClick: () => void;
    isNavOpen: boolean;
}

const HamburgerContainer = styled.button`
    display: none;
    box-shadow: none;
    border: none;
    background-color: transparent;
    width: 24px;
    height: 24px;
    padding: 0px;
    margin: 0px;
    margin-right: ${Spacing.SPACING_1};

    path {
        stroke: ${Color.TEXT_LIGHT_1};
    }

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        display: flex;
    }
`;

const HamburgerButton = ({ onClick, isNavOpen }: HamburgerProps) => {
    return (
        <HamburgerContainer onClick={onClick}>
            {isNavOpen ? (
                <CloseIcon width="24px" height="24px" />
            ) : (
                <Hamburger width="24px" height="24px" />
            )}
        </HamburgerContainer>
    );
};

const NavModal = styled.div`
    position: absolute;
    z-index: 100;
    width: 100vw;
    height: calc(100vh - 63px);
    left: 0px;
    top: 63px;
    background-color: ${Color.BRAND_1};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ${Spacing.SPACING_2};
`;

const NavModalNav = styled.nav`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: ${Spacing.SPACING_2} 0px;

    .activeLink {
        font-weight: 700;
    }
`;

const NavModalLink = styled(UnstyledLink)`
    font-size: 22px;
    color: ${Color.TEXT_LIGHT_1};
    margin-right: ${Spacing.SPACING_2};
    text-decoration: none;
    margin-bottom: ${Spacing.SPACING_1};
`;

const Link = styled(UnstyledLink)`
    font-size: ${FontSize.NAVIGATION};
    color: ${Color.TEXT_LIGHT_1};

    margin-right: ${Spacing.SPACING_2};
    text-decoration: none;

    :last-of-type {
        margin-right: 0;
    }
`;

const LogoLink = styled(UnstyledLink)`
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`;

const Footer = styled.footer`
    width: 100%;
    height: 300px;
    margin-top: auto;
    background-color: ${Color.BACKGROUND_DARK_1};
    padding: ${Spacing.SPACING_3};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        padding-bottom: ${Spacing.SPACING_7};
        height: 360px;
    }
`;

const FooterSocket = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    @media (${Breakpoint.TABLET_AND_DOWN}) {
        flex-direction: column;
    }
`;

const CopyrightText = styled.p`
    color: ${Color.TEXT_LIGHT_1};
    font-size: 18px;
    margin: 0;
`;

interface Props {
    children: ReactNode;
    title: string;
}

const Layout = ({ children, title }: Props) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleNav = useCallback(() => {
        setIsNavOpen((navState) => !navState);
    }, []);

    const openModal = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    // Temp cookie override
    const location = useLocation();
    useEffect(() => {
        Cookie.set(GDPR_COOKIE, "true");
        initializeAndTrack(location);
    }, [location]);

    return (
        <Fragment>
            <GlobalStyle />
            <Helmet>
                <title>{title}</title>
                <link rel="icon" href="/Favicon512.png" sizes="512x512"></link>
                <link rel="icon" href="/Favicon192.png" sizes="192x192"></link>
                <link rel="icon" href="/Favicon32.png" sizes="32x32"></link>
                <link
                    rel="apple-touch-icon"
                    href="/Favicon180.png"
                    sizes="180x180"
                ></link>
            </Helmet>
            <Container preventScroll={isNavOpen}>
                <ScrollContainer>
                    <Header>
                        <HeaderLeftSocket>
                            <HamburgerButton onClick={toggleNav} isNavOpen={isNavOpen} />
                            <LogoLink to="/">
                                <Logo />
                            </LogoLink>
                        </HeaderLeftSocket>
                        <HeaderCentralSocket>
                            <Nav>
                                <Link
                                    to="/about-us"
                                    activeClassName="activeLink"
                                >
                                    About Us
                                </Link>
                                <Link
                                    to="/learning-path"
                                    activeClassName="activeLink"
                                >
                                    Learning Path
                                </Link>
                                <Link to="/faq" activeClassName="activeLink">
                                    FAQ
                                </Link>
                                <Link to="https://blog.learncmd.io/" activeClassName="activeLink">
                                    Blog
                                </Link>
                                {/*
                                <Link
                                    to="/go-to-app"
                                    activeClassName="activeLink"
                                >
                                    Go To App
                                </Link>
                                */}
                            </Nav>
                        </HeaderCentralSocket>
                        <HeaderRightSocket>
                            <Button
                                buttonType={ButtonType.SECONDARY_WHITE}
                                buttonSize={ButtonSize.REGULAR}
                                onClick={() => window.location.href = 'https://app.learncmd.io'}
                            >
                                Log In
                            </Button>
                        </HeaderRightSocket>
                    </Header>
                    {isNavOpen && (
                        <NavModal>
                            <NavModalNav>
                                <NavModalLink
                                    to="/about-us"
                                    activeClassName="activeLink"
                                >
                                    About Us
                                </NavModalLink>
                                <NavModalLink
                                    to="/learning-path"
                                    activeClassName="activeLink"
                                >
                                    Learning Path
                                </NavModalLink>
                                <NavModalLink
                                    to="/faq"
                                    activeClassName="activeLink"
                                >
                                    FAQ
                                </NavModalLink>
                                <NavModalLink
                                    to="https://blog.learncmd.io/"
                                    activeClassName="activeLink"
                                >
                                    Blog
                                </NavModalLink>
                                {/*
                                <NavModalLink
                                    to="/go-to-app"
                                    activeClassName="activeLink"
                                >
                                    Go To App
                                </NavModalLink>
                                */}
                            </NavModalNav>
                        </NavModal>
                    )}
                    {isModalOpen && (
                        <Modal closeAction={closeModal}>
                            <SubscribeByMail close={closeModal} />
                        </Modal>
                    )}
                    {children}
                    <Footer>
                        <FooterSocket>
                            <Logo width="225" height="40" />
                            <Nav inFooter>
                                <Link to="/about-us">About Us</Link>
                                <Link to="/learning-path">Learning Path</Link>
                                <Link to="/faq">FAQ</Link>
                                <Link to="https://blog.learncmd.io/">Blog</Link>
                                {/* Temp cookie override */}
                                {/* <Link to="/cookie-policy">Cookie Policy</Link> */}
                            </Nav>
                        </FooterSocket>
                        <FooterSocket>
                            <CopyrightText>
                                Čaljkušić Technology d.o.o, Marulovo 33, Okrug
                                Gornji, Croatia © 2021 — present
                            </CopyrightText>
                        </FooterSocket>
                    </Footer>
                </ScrollContainer>
                {/* Temp cookie override */}
                {/* <GDPRModal /> */}
            </Container>
        </Fragment>
    );
};
export default Layout;
